import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Vscode Language Specific Configs",
  "author": "tzookb",
  "type": "post",
  "date": "2019-12-10T13:50:08.000Z",
  "url": "/vscode-language-specific-configs",
  "categories": ["vscode"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Vscode injected itself to the hearts of many developers around the globe quite quickly.`}</p>
    <p>{`I love it and especially the way I can easily configure it to what I need.`}</p>
    <p>{`For quite some time I was struggling with a project that had different languages that
required different tab sizes (2 or 4).`}</p>
    <p>{`I tried several tricks to resolve that pain.`}</p>
    <p>{`loaded the main project folder and ignore the sub folder that contains the other language.
That helped a bit, but there were configs in the root folder that were needed in the sub folder.`}</p>
    <p>{`Second, I opened two vscode instances one for each type of code. annoying.`}</p>
    <h4>{`The solution`}</h4>
    <p>{`Simply have different config per language. So I could easily define different tab size per languge or any other config I needed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "global_config_here": "...",
  "editor.tabSize": 4
  
  //only javascript files configs now
  "[javascript]": {
      "editor.tabSize": 2
  },
  //only javascript-react files configs now
  "[javascriptreact]": {
      "editor.tabSize": 2
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      